    import React from 'react';
import ApiURL from './ApiURL';
import Loader from './Loader';
import ReactMarkdown from 'react-markdown';

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFetching: true,
            loaded: false,
            contactContent: [],
            name: '',
            email: '',
            phone: '',
            service: 'general',
            formSubmitted: false
        }
        this.fetchContactContent();

        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleServiceChange = this.handleServiceChange.bind(this);
        this.handleMessageChange = this.handleMessageChange.bind(this);
    }
    render() {
        return(
            <div className="contact">
                <div className="contactLeftPanel">
                    <h1>Contact</h1>
                    <p className="contactContent"><ReactMarkdown children={this.state.contactContent.Content} /></p>
                    <div className="contactRow">
                        <i className="fa fa-envelope"></i>
                        <h3 className="contactEmail">
                            <a href={ 'mailto:' + this.state.contactContent.GeneralEnquiries }>
                                General Enquiries<br />
                                { this.state.contactContent.GeneralEnquiries }
                            </a>
                        </h3>
                    </div>
                    
                    {/* <div className="contactRow"> */}
                        {/* <i className="fa fa-envelope"></i> */}
                        {/* <h3 className="contactEmail"> */}
                            {/* <a href={ 'mailto:' + this.state.contactContent.MuralEnquiries }> */}
                                {/* Mural Enquiries<br /> */}
                                {/* { this.state.contactContent.MuralEnquiries } */}
                            {/* </a> */}
                        {/* </h3> */}
                    {/* </div> */}
                    {/* <div className="contactRow"> */}
                        {/* <i className="fa fa-envelope"></i> */}
                        {/* <h3 className="contactEmail"> */}
                            {/* <a href={ 'mailto:' + this.state.contactContent.MusicEnquiries }> */}
                                {/* Music Enquiries<br /> */}
                                {/* { this.state.contactContent.MusicEnquiries } */}
                            {/* </a> */}
                        {/* </h3> */}
                    {/* </div> */}
                    <div className="contactRow">
                        <i className="fa fa-phone"></i>
                        <h3  className="contactPhone">
                            <a href={ 'tel:' + this.state.contactContent.Phone }>
                                { this.state.contactContent.Phone }
                            </a>
                        </h3>
                    </div>
                    <div className="contactRow">
                        <i className="fa fa-map-marker-alt"></i>
                        <h3 className="contactLocation">
                            { this.state.contactContent.Location }
                        </h3>
                    </div>
                </div>
                <div className="contactRightPanel">
                    <form onSubmit={() => {this.handleSubmit()}}>
                        <div className="contactFormGrid">
                            <input type="text" value={this.state.name} onChange={this.handleNameChange} placeholder="Your name*" />
                            <input type="email" value={this.state.email} onChange={this.handleEmailChange} placeholder="Your email*" />
                            <input type="phone" value={this.state.phone} onChange={this.handlePhoneChange} placeholder="Your contact number*"/>
                            <select 
                                value={this.state.service} 
                                onChange={this.handleServiceChange} 
                                placeholder="Service you require"
                            >
                                <option value="general">General Enquiries</option>
                                <option value="mural">Mural Enquiries</option>
                                <option value="music">Music Enquiries</option>
                                <option value="other">Other Enquiries</option>
                            </select>
                        </div>
                        <textarea placeholder="Your message or enquiry" value={this.state.message} onChange={this.handleMessageChange}/>
                        <button>Submit</button>
                    </form>    
                </div>
                <Loader loading={ this.state.isFetching } loaded={ this.state.loaded }/>
            </div>
        )

    } 
    fetchContactContent() {
        this.setState({isFetching: true});
        fetch(ApiURL + '/contact')
            .then(response => response.json())
            .then(result => {
                this.setState({
                    contactContent: result,
                    isFetching: false,
                    loaded: true,
                });
                console.log('success');
            })
            .catch(e => {
                this.setState({isFetching: false});
                console.log('failed');
            });
    }
    handleNameChange(event) {
        this.setState({
            name: event.target.value
        })
    } 
    handleEmailChange(event) {
        this.setState({
            email: event.target.value
        })
    } 
    handlePhoneChange(event) {
        this.setState({
            phone: event.target.value
        })
    }
    handleServiceChange(event) {
        this.setState({
            service: event.target.value
        })
    }
    handleMessageChange(event) {
        this.setState({
            message: event.target.value
        })
    }
    handleSubmit(event) {
        let data = {
            Name: this.state.name,
            Email: this.state.email,
            Phone: this.state.phone,
            Service: this.state.service,
            Message: this.state.message,
        };
        
        fetch(ApiURL + '/enquiries', {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(result => {
            this.setState({
                formSubmitted: true
            })
        })
        .catch(e => {
            console.log('failed');
        });
        event.preventDefault();
    }
}


export default Contact;