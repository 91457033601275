import React from 'react';
import ApiURL from './ApiURL';
import Gallery from './Gallery';
import Loader from './Loader';
import ReactMarkdown from 'react-markdown';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

class CaseStudy extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            caseStudies: [],
            currentCaseStudy: false,
            loaded: false,
            isFetching: true,
            caseStudyObject: {},
            mapOpen: window.location.hash == '#map' ? true : false
        };

        this.fetchCaseStudies();

        
    }

    componentWillUnmount() {
        document.body.classList.remove('withMap');
    }

    render() {
        

        if(this.state.caseStudyObject?.Longitude && this.state.caseStudyObject?.Latitude) {
            document.body.classList.add('withMap');
        } else {
            document.body.classList.remove('withMap');
        }
        return(
            <div className="caseStudies">
                <div className="caseStudiesTitle">
                    <h1>Case Studies</h1>
                </div>
                <div className="teamNav">
                    {
                        this.state.caseStudies.map((item, index) => {
                            return(
                                <CaseStudyNavItem
                                    item={item} 
                                    index={index}  
                                    key={index}   
                                    parent={this}
                                    active={index === this.state.currentCaseStudy}
                                />
                            )
                        })
                    }
                    <button className="sliderButton last" onClick={() => this.previousCaseStudy()}>
                        <i className="fa fa-chevron-up"></i>
                    </button>
                    <button className="sliderButton next"  onClick={() => this.nextCaseStudy()}>
                        <i className="fa fa-chevron-down"></i>
                    </button>
                </div>
                <div className="currentCaseStudy">
                    <div className="mobileOnly">
                        <div className="caseStudySubtitle">
                                {this.state.caseStudyObject?.Subtitle}
                        </div>
                        <h2 className="caseStudyTitle">{this.state.caseStudyObject?.Title}</h2>
                    </div>
                    <Gallery 
                        data={ 
                            this.state.caseStudies.length ? 
                            this.state.caseStudies[this.state.currentCaseStudy].Gallery : 
                            [] 
                        } 
                        key={
                            this.state.currentCaseStudy
                        }
                        columns={2.5} 
                    />
                    <div className="caseStudyContent">
                        <div>
                            <div className="caseStudySubtitle">
                                {this.state.caseStudyObject?.Subtitle}
                            </div>
                            <h2 className="caseStudyTitle">{this.state.caseStudyObject?.Title}</h2>
                            <div>
                                <ReactMarkdown>
                                    {this.state.caseStudyObject?.Paragraph1}
                                </ReactMarkdown>
                            </div>
                        </div>
                        <div>
                            <ReactMarkdown>
                                {this.state.caseStudyObject?.Paragraph2}
                            </ReactMarkdown>
                        </div>
                        <div>
                            <ReactMarkdown>
                                {this.state.caseStudyObject?.Paragraph3}
                            </ReactMarkdown>
                        </div>
                    </div>
                    {
                      this.state.caseStudyObject?.Video ?
                        <div className={ `caseStudyMap ${this.state.mapOpen ? '' : 'closed'} ${this.state.loaded ? 'peek' : ''}` }> 
                            <div className="caseStudyMapHeader">
                                <button 
                                    className={ `caseStudyMapTrigger ${this.state.mapOpen ? 'open' : 'closed'}` }
                                    onClick={
                                        () => {
                                            this.setState({mapOpen: !this.state.mapOpen})
                                        }
                                    }
                                ><i class="fa fa-chevron-left"></i>VIDEO</button>
                            </div>
                            <div className="caseStudyMapBody">
                            <iframe src={["https://player.vimeo.com/video/" + this.state.caseStudyObject?.Video]} width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>
                        : ''
                    }
                </div>
                <Loader loading={ this.state.isFetching } loaded={ this.state.loaded }/>
            </div>
        );
    }
    fetchCaseStudies() {
        this.setState({isFetching: true});
        fetch(ApiURL + '/case-studies?_limit=5&_sort=updated_at:DESC')
            .then(response => response.json())
            .then(result => {
                let currentCaseStudy = this.props.match.params.id === undefined ? 0 : this.props.match.params.id;
                if(currentCaseStudy >= result.length) {
                    currentCaseStudy = 0;
                }
                this.setState({
                    caseStudies: result,
                    isFetching: false,
                    loaded: true,
                    currentCaseStudy: currentCaseStudy,
                    caseStudyObject: result[currentCaseStudy]                    
                });
            })
            .catch(e => {
                this.setState({isFetching: false});
            });
    }

    setActiveCaseStudy(index) {
        this.setState({
            currentCaseStudy: index,
            caseStudyObject: this.state.caseStudies[index]
        });
    }

    nextCaseStudy() {
        let index = this.state.currentCaseStudy;
        index++;
        if(index >= this.state.caseStudies.length) {
            index = 0;
        }

        this.setActiveCaseStudy(index);
    }

    previousCaseStudy() {
        let index = this.state.currentCaseStudy;
        index--;
        if(index < 0) {
            index = this.state.caseStudies.length - 1;
        }
        this.setActiveCaseStudy(index);
    }
}

function CaseStudyNavItem(props) {
    let maybeActive = props.active ? 'active' : '';
    let classes = `teamNavIndicator ${maybeActive}`;
    let thumbUrl = props.item.Thumbnail ? ApiURL + props.item.Thumbnail.url : '';

    return(
        <div className="teamNavIndicatorWrap">
            <div 
                key={props.index} 
                className={classes} 
                onClick={() => props.parent.setActiveCaseStudy(props.index)}
            > 
                <img 
                    src={thumbUrl} 
                    alt={props.item.Title}
                />
            </div>
        </div>
    )
}

const MyMapComponent = withGoogleMap((props) =>
  <GoogleMap
    defaultZoom={14}
    defaultCenter={{ lat: props.lat, lng: props.lng }}
    options={{
        fullscreenControl: false,
        disableDefaultUI: true,
        styles: exampleMapStyles
    }}
  >
    {props.isMarkerShown && <Marker position={{ lat: props.lat, lng: props.lng }} />}
  </GoogleMap>
)

const exampleMapStyles = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#212121"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#212121"
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "administrative.country",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative.locality",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#181818"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#1b1b1b"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#2c2c2c"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#8a8a8a"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#373737"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#3c3c3c"
        }
      ]
    },
    {
      "featureType": "road.highway.controlled_access",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#4e4e4e"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#000000"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#3d3d3d"
        }
      ]
    }
  ];


export default CaseStudy;
