import React from 'react';
import { Link } from 'react-router-dom';
import ApiURL from './ApiURL';
import ReactMarkdown from 'react-markdown';

class CurrentService extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            serviceId: props.id,
            isFetching: false,
            loaded: false,
            content: [],
            activeSubservice: 0,
            subservices: [],
            scroll: 0,
            aggregate: 0
        };
        this.fetchContent();
    }

    componentDidMount() {
        window.onwheel = this.handleScroll.bind(this);
    }

    componentWillUnmount() {
        window.onwheel = null;
    }

    handleScroll(event) {
        let newScroll = this.state.scroll - event.deltaY / 20;
        let newActive = this.state.activeSubservice - parseInt(newScroll / 100);
        newScroll = newScroll % 100;

        if (newActive === 0 && newScroll > 0) {
            newScroll = 0;
        }

        if (newActive >= this.state.subservices.length - 1) {
            newActive = this.state.subservices.length - 1;
            newScroll = newScroll < -30 ? -30 : newScroll;
        }

        this.setState({
            scroll: newScroll,
            activeSubservice: newActive,
        });

        this.recalculateAggregate();
    }

    recalculateAggregate() {
        let newAggregate = (this.state.activeSubservice - (this.state.scroll / 100)) / (this.state.subservices.length - 0.7) * 100 + '%';
        this.setState({
            aggregate: newAggregate
        })
    }

    render() {
        if(this.state.serviceId === undefined) {
            return (<div className="currentService"></div>);
        }
        return (
            <div className="currentService">
                <div className="currentServiceLeft">
                    <p className="servicesTitle">SERVICES</p>
                    <div className="currentServiceLeftInner">
                        <div className="currentServiceContent">
                            <p>
                                <ReactMarkdown>
                                    {this.state.content ? this.state.content.Content : ''}
                                </ReactMarkdown>
                            </p>
                        </div>
                        <SubserviceNav items={this.state.content ? this.state.content.Subservices : []} parent={this} />
                        <div className="currentServiceProgressBar">
                            <button 
                                className="sliderButton"
                                onClick={() => {
                                    this.previousSubservice();
                                }}
                            >
                                <i className="fa fa-chevron-left"></i>
                            </button>
                            <button 
                                className="sliderButton"
                                onClick={() => {
                                    this.nextSubservice();
                                }}
                            >
                                <i className="fa fa-chevron-right"></i>
                            </button>
                            <div className="caseStudyProgressBar">
                                <div
                                    className="caseStudyProgressBarFiller"
                                    id="caseStudyProgressBarFiller"
                                    style={{
                                        width: this.state.aggregate
                                    }}
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="currentSubservice">
                    <div
                        className="subserviceStage"
                        style={{
                            width: 130 * this.state.subservices.length + '%',
                            left: -130 * this.state.activeSubservice + this.state.scroll + '%'
                        }}
                    >
                        {
                            this.state.subservices.map((item, index) => {
                                return (
                                    <Subservice
                                        key={index}
                                        data={item}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
    fetchContent() {
        this.setState({ isFetching: true });
        fetch(ApiURL + '/services/' + this.state.serviceId)
            .then(response => response.json())
            .then(result => {
                this.setState({
                    content: result,
                    subservices: result.Subservices,
                    isFetching: false,
                    loaded: true,
                });
            })
            .catch(e => {
                this.setState({ isFetching: false });
            });

    }

    setActiveSubservice(key) {
        this.setState({
            activeSubservice: key,
            scroll: 0
        }, () => {
            this.recalculateAggregate();
        });
    }

    nextSubservice() {
        let newKey = this.state.activeSubservice;
        newKey++;
        if(newKey >= this.state.subservices.length) {
            newKey = 0;
        }        
        this.setActiveSubservice(newKey);
    }

    previousSubservice() {
        let newKey = this.state.activeSubservice;
        newKey--;
        if(newKey < 0) {
            newKey = this.state.subservices.length - 1;
        }        
        this.setActiveSubservice(newKey);
    }
}

function SubserviceNav(props) {
    return (
        <div className="subserviceNav">
            <p className="subserviceNavTitle">{props.parent.state.content ? props.parent.state.content.Title + ' Services' : ''}</p>
            {
                props.items ? props.items.map((item, index) => {
                    return (
                        <SubserviceNavItem
                            text={item.Title}
                            key={index}
                            index={index}
                            currentService={props.parent}
                            active={index === props.parent.state.activeSubservice}
                        />
                    )
                }) : ''
            }
        </div>
    );
}

function SubserviceNavItem(props) {
    return (
        <div className="subserviceNavItem" onClick={() => { props.currentService.setActiveSubservice(props.index) }}>
            <p className={`subserviceNavItemInner ${props.active ? 'active' : ''}`}>
                {props.text.toUpperCase()}
            </p>
        </div>
    );
}

function Subservice(props) {
    return (
        <div className="subservice">
            <div className="subserviceGallery" data-items={props.data.Gallery.length}>
                {
                    props.data.Gallery ? props.data.Gallery.map((item, index) => {
                        return (
                            <div
                                className="subserviceGalleryItem"
                                style={{
                                    backgroundImage: 'url(' + ApiURL + item.url + ')',
                                    backgroundSize: 'cover'
                                }}
                            >
                            </div>
                        )
                    }) : ''
                }
            </div>
            <div className="subserviceTitle">
                {props.data.Title}
            </div>
            <div className="subserviceDescription">
                <ReactMarkdown>
                    {
                        props.data.Description
                    }
                </ReactMarkdown>
                <Link to="/contact" className="subserviceCta">Get in touch about <span>{props.data.Title}</span></Link>
            </div>
        </div>
    )
}

export default CurrentService;