import { Link } from 'react-router-dom';
import { matchPath, useLocation } from 'react-router'

function AboutNav(props) {
    return(
        <div className="aboutNav">
            <AboutNavItem to="/about" text="Street Arts Hire" />
            <AboutNavItem to="/about/team" text="Meet the Collective" />
        </div>
    );
}

function AboutNavItem(props) {
    let location = useLocation();
    return(
        <Link 
            to={props.to} 
            className={ 
                matchPath(location.pathname, 
                    {
                        path: props.to,
                        exact: true
                    }
                ) ? 'active' : '' 
            } 
        >
            <li>{props.text}</li>
        </Link>
    );
}

export default AboutNav;