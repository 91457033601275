import React from 'react';

class Loader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: props.loading || false
        }
    }
    render() {
        return(
            <div className={`loader ${ this.props.loading ? "active" : ""} ${ this.props.loaded ? "loaded" : ""}`}>
            </div>
        )            
    }
}

export default Loader;