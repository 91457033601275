import React from 'react';
import AboutNav from './AboutNav';
import ApiURL from './ApiURL';
import Loader from './Loader';
import ReactMarkdown from 'react-markdown';
import Gallery from './Gallery';

class About extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFetching: true,
            aboutContent: [],
            loaded: false
        };
        this.fetchAboutContent();
    }
    render() {
        return(
            <div className="about">
                <AboutNav />
                {
                    this.state.loaded ? 
                    <div className="aboutRow">
                        <div className="quoteImageWrap">
                            <img 
                                src={ ApiURL + this.state.aboutContent.QuoteImage.url } 
                                alt={ this.state.aboutContent.QuoteImage.alternativeText }
                            />
                        </div>
                        <Gallery 
                            data={ this.state.aboutContent.Gallery } 
                            columns={3} 
                            mobileColumns={2.25}
                        />
                    </div> : '' 
                }
                {   
                    this.state.loaded ?
                    <div className="aboutRow">
                        <p className="aboutContent"><ReactMarkdown children={this.state.aboutContent.Content} /></p>
                        <div className="streetArtsImageWrap">
                            <img 
                                src={ ApiURL + this.state.aboutContent.StreetArtsImage.url } 
                                alt={ this.state.aboutContent.StreetArtsImage.alternativeText }
                            />
                        </div>
                    </div> : ''
                }
                <Loader loading={ this.state.isFetching } loaded={ this.state.loaded } />
            </div>
        );
    }
    fetchAboutContent() {
        this.setState({isFetching: true});
        fetch(ApiURL + '/about')
            .then(response => response.json())
            .then(result => {
                this.setState({
                    aboutContent: result,
                    isFetching: false,
                    loaded: true
                });
                console.log('success');
            })
            .catch(e => {
                this.setState({isFetching: false});
                console.log('failed');
            });
    }
}

export default About;