import React from 'react';
import ApiURL from './ApiURL';
import Loader from './Loader';
import CaseStudySlide from './CaseStudySlide';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            isFetching: true,
            caseStudies: [],
            activeCaseStudy: false,
            activeCaseStudyId: false,
            left: 0,
            navLeft: -100,
        }
        this.fetchCaseStudies();
        this.timeout = setTimeout(() => this.handleTimeout(), 6000);
    }

    render() {
        return(
            <div className="home">
                <div className="homeInner">
                    <div className="caseStudySlider">
                        <div 
                            className="caseStudySliderStage" 
                            style={{ 
                                width : 100 * this.state.caseStudies.length + 'vw',
                                left: this.state.left + 'vw'
                            }}
                        >
                            {
                                this.state.caseStudies.map((item, index) => {
                                    return(
                                        <CaseStudySlide 
                                            data={item} 
                                            key={index} 
                                            index={index}
                                            active={index === this.state.activeCaseStudy} 
                                            left={index < this.state.activeCaseStudy} 
                                            right={index > this.state.activeCaseStudy} 
                                            parent={this}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="caseStudySliderControls">
                        <button className="sliderButton" onClick={() => {this.regressSlider()}}>
                            <i className="fa fa-chevron-left"></i>
                        </button>
                        <button className="sliderButton" onClick={() => {this.handleTimeout()}}>
                            <i className="fa fa-chevron-right"></i>
                        </button>
                        <div className="caseStudyProgressBar">
                            <div 
                                className="caseStudyProgressBarFiller" 
                                id="caseStudyProgressBarFiller"
                                style={{
                                    width: 100 * (this.state.activeCaseStudy + 1) / this.state.caseStudies.length + '%'
                                }}    
                            ></div>
                        </div>
                        <div className="activeCaseStudyNumber">
                            { '0' + (this.state.activeCaseStudy + 1) }
                        </div>
                    </div>
                    <div className="caseStudySliderNav">
                        <div 
                            className="caseStudySliderNavStage"
                            style={{ 
                                width : 100 / 2 * this.state.caseStudies.length + '%',
                                left: this.state.navLeft / 4 + '%'
                            }}
                        >
                            {
                                this.state.caseStudies.map((item, index) => {
                                    return(
                                        <div 
                                            className="" 
                                            key={index} 
                                            onClick={ () => this.moveSlider(index) }
                                            style={{
                                                backgroundImage: `url(${ ApiURL + item.Hero.formats.medium.url })`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center center' 
                                            }}
                                        >
                                        </div>
                                    )
                                })
                            }
                            {
                                this.state.caseStudies.map((item, index) => {
                                    return(
                                        <div 
                                            className="" 
                                            key={index} 
                                            onClick={ () => this.moveSlider(index) }
                                            style={{
                                                backgroundImage: `url(${ ApiURL + item.Hero.formats.medium.url })`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center center' 
                                            }}
                                        >
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <Loader loading={ this.state.isFetching } loaded={ this.state.loaded }/>
            </div>
        );

    }

    fetchCaseStudies() {
        this.setState({isFetching: true});
        fetch(ApiURL + '/case-studies?_limit=5')
            .then(response => response.json())
            .then(result => {
                this.setState({
                    caseStudies: result,
                    isFetching: false,
                    loaded: true
                });
                if(this.state.caseStudies.length > 0) {
                    this.setState({
                        activeCaseStudy: 0,
                    })
                }
            })
            .catch(e => {
                this.setState({isFetching: false});
            });
    }

    handleTimeout() {
        let newSlide = this.state.activeCaseStudy + 1;
        newSlide = newSlide >= this.state.caseStudies.length ? 0 : newSlide;
        this.moveSlider(newSlide);        
    }

    regressSlider() {
        let newSlide = this.state.activeCaseStudy - 1;
        newSlide = newSlide < 0 ? this.state.caseStudies.length - 1 : newSlide;
        this.moveSlider(newSlide);
    }

    moveSlider(newSlide) {
        let left = -100 * newSlide;
        let navLeft = -100 * (newSlide + 1);
        this.setState({
            activeCaseStudy: newSlide,
            left: left,
            navLeft: navLeft
        });
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => this.handleTimeout(), 6000);
    }
}


export default Home;