import React from 'react';
import Loader from './Loader';
import ApiURL from './ApiURL';
import { NavLink } from 'react-router-dom'
import { withRouter, useLocation, matchPath } from 'react-router';
import CurrentService from './CurrentService';

class Services extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            services: [],
            currentService: this.props.match.params.id,
            isFetching: true,
            loaded: false
        }

        this.fetchServices();
    }

    render() {
        return(
            <div className="services">
                <ServicesNav services={ this.state.services }/> 
                <Loader loading={ this.state.isFetching } loaded={ this.state.loaded } />
                <CurrentService key={ this.props.match.params.id } id={ this.props.match.params.id } />
            </div>
        );
    } 

    fetchServices() {
        this.setState({isFetching: true});
        fetch(ApiURL + '/services')
            .then(response => response.json())
            .then(result => {
                this.setState({
                    services: result,
                    isFetching: false,
                    loaded: true,
                    currentService: this.props.match.params.id ? this.props.match.params.id : result[0].id,
                });
            })
            .catch(e => {
                this.setState({isFetching: false});
            });
    }
}

function ServicesNav(props) {
    return(
        <div className="aboutNav">
            {
                props.services ? props.services.map((item, index) => {
                    return(
                        <ServicesNavItem key={index}
                                         to={"/services/" + item.id}
                                         text={item.Title}
                                         index={index} />
                    )
                }) : ''
            }
        </div>
    );
}

function ServicesNavItem(props) {
    let location = useLocation();
    return(
        <NavLink
            to={props.to}
            className={
                matchPath(location.pathname,
                    {
                        path: props.to,
                        exact: true
                    }
                ) ? 'active' : ''
            } 
        >
            <li>{props.text}</li>
        </NavLink>
    );
}

export default withRouter(Services);