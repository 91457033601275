import React from 'react';
import ApiURL from './ApiURL';
import AboutNav from './AboutNav';
import Gallery from './Gallery';
import Loader from './Loader';

class Team extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFetching: true,
            activeMember: false,
            lastActiveMember: false,
            loaded: false,
            team: []
        };
        this.fetchTeamMembers();
    }

    render() {
        return(
            <div className="team">
                <AboutNav />
                <div className="teamNav">
                    {
                        this.state.team.map((item, index) => {
                            return(
                                <TeamNavItem
                                    item={item} 
                                    index={index}  
                                    key={index}   
                                    parent={this}
                                />
                            )
                        })
                    }
                    <button className="sliderButton last" onClick={() => this.previousMember()}>
                        <i className="fa fa-chevron-up"></i>
                    </button>
                    <button className="sliderButton next"  onClick={() => this.nextMember()}>
                        <i className="fa fa-chevron-down"></i>
                    </button>
                </div>
                <div className="activeTeamMember">
                    {
                        (this.state.team.length > 0 && this.state.activeMember !== false) ? 
                            <ActiveMember 
                                parent={this} 
                                data={this.state.team[this.state.activeMember]}
                            /> : 
                            ''
                    }
                </div>
                <Loader loading={ this.state.isFetching } loaded={ this.state.loaded } />
            </div>
            
        )
    }

    fetchTeamMembers() {
        this.setState({isFetching: true});
        fetch(ApiURL + '/team-members?_sort=order:ASC')
            .then(response => response.json())
            .then(result => {
                this.setState({
                    team: result,
                    activeMember: 0,
                    isFetching: false,
                    loaded: true
                });
                console.log('success');
            })
            .catch(e => {
                this.setState({isFetching: false});
                console.log('failed');
            });
    }

    setActiveMember(index) {
        this.setState({
            lastActiveMember: this.state.activeMember,
            activeMember: index
        });
    }

    nextMember() {
        let index = this.state.activeMember;
        index++;
        if(index >= this.state.team.length) {
            index = 0;
        }
        this.setActiveMember(index);
    }

    previousMember() {
        let index = this.state.activeMember;
        index--;
        if(index < 0) {
            index = this.state.team.length - 1;
        }
        this.setActiveMember(index);
    }
}
export default Team;

function TeamNavItem(props) {
    let maybeActive = props.parent.state.activeMember === props.index ? 'active' : '';
    let classes = `teamNavIndicator ${maybeActive}`;

    return(
        <div className="teamNavIndicatorWrap">
            <div key={props.index} className={classes} onClick={() => props.parent.setActiveMember(props.index)}> 
            {
                props.item.IndicatorImage ?
                <img 
                    src={props.item.IndicatorImage ? ApiURL + props.item.IndicatorImage.url : ''} 
                    alt={props.item.Name}
                />
                : ''
            }
            </div>
        </div>
    )
}

function ActiveMember(props) {

    let data = props.data;

    if(props.indexOverride) {
        data = props.parent.state.team[props.indexOverride];
    }

    return(
        <div className="activeMember">
            <div className="activeMemberHeader">
                <div className="activeMemberHeaderLeft">
                    <p className="activeMemberSubtitle">{data.Subtitle}</p>
                    <h1 className="activeMemberName">{data.Name}</h1>
                    {
                        data.Contact ? <p className="activeMemberContact">{data.Contact}</p> : ''
                    }
                </div>
                {
                    data.Headshot ? 
                    <div className="headshotWrap">
                        <img 
                            className="headshot"
                            src={ data.Headshot ? ApiURL + data.Headshot.url : ''} 
                            alt={data.Name}
                        />
                    </div>
                    : ''
                }
                <div className="activeMemberHeaderRight">
                    <svg alt={data.Location}>
                        <text 
                            className="activeMemberLocation" 
                            x="0" 
                            y="15vh"
                            fill="none"
                            stroke="#fff"
                            fontSize="15vh"
                            fontFamily="DIN Condensed Bold"
                        >{data.Location}</text>
                    </svg>
                </div>
            </div>
            <div className="activeMemberBioGrid">
                <p>{data.Bio1}</p>
                <p>{data.Bio2}</p>
                <p>{data.Bio3}</p>
            </div>
            <div className="activeMemberGallery">
                {
                    props.data.Gallery.length > 0 ? 
                        <Gallery 
                            data={props.data.Gallery} 
                            key={props.data.id} 
                            columns={4.5} 
                            mobileColumns={2.25}
                        />
                    : ''
                }
            </div>
        </div>
    );
}