import React from "react";
import { Link, matchPath, useLocation } from "react-router-dom";
import logo from './logo.png';

class Nav extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            menuOpen: false
        }
    }

    componentWillUnmount() {
        document.body.setAttribute('style', '');
    }

    render() {
        return(
            <nav>
                <Link 
                    to="/"
                    onClick={() => { this.closeHamburger() }}
                >
                    <img src={logo} alt="Street Arts Hire" />
                </Link>
                <ul 
                    id="headerNav"
                    className={`headerNav ${this.state.menuOpen ? 'active' : ''}`}
                >
                    <div 
                        className="headerOverlay"
                        onClick = {() => { this.closeHamburger() }}
                    >
                    </div>
                    <NavItem parent={this} to="/about" text="About Us"/>
                    <NavItem parent={this} to="/services/1" text="Services"/>
                    {/* <a href="https://rarible.com/streetartsnft" rel="noreferrer" target="_blank"><li>NFT</li></a> */}
                    <NavItem parent={this} to="/portfolio" text="Portfolio"/>
                    <NavItem parent={this} to="/case-studies" text="Case Studies"/>
                    <NavItem parent={this} to="/map" text="Map"/>
                    <NavItem parent={this} to="/contact" text="Contact Us"/>
                    <a href="https://geek.design/" rel="noreferrer" target="_blank" className="geekLink mobileOnly" ><li><img src="/G_White.svg" className="geekG" /></li></a>
                </ul>
                <button 
                    className="hamburgerTrigger"
                    onClick={() => { this.toggleHamburger() }}
                ><span>{ this.state.menuOpen ? '✕' : '☰' }</span></button>
            </nav>
        );
    }

    toggleHamburger() {
        this.setState({
            menuOpen: !this.state.menuOpen
        }, () => {
            if(this.state.menuOpen) {
                let offset = window.scrollY;
                document.body.setAttribute('style', `position: fixed; top: -${offset}px; left: 0; right: 0`);
            } else {
                document.body.setAttribute('style', '');    
            }
        });
    }

    closeHamburger() {
        this.setState({
            menuOpen: false
        }, () => {
            document.body.setAttribute('style', '');
        });
    }
}

function NavItem(props) {
    let to = props.to;
    let text = props.text;
    let location = useLocation();
    let active = matchPath(location.pathname, to) ? 'active' : '';
    return(
        <Link 
            to={to} 
            className={`menuItem ${active}`}
            onClick={ () => props.parent.closeHamburger() }
        >
            <li>{text}</li>
        </Link>
    );
}

export default Nav;