import React from 'react';

class NotFound extends React.Component {
    constructor(props) { 
        super(props);
        this.state = {

        };
    }
    render() {
        return(
            <div className="notFound">
                <h1>404</h1>
                <h2>Not Found!</h2>
            </div>
        );
    }
}

export default NotFound;