import React from "react";
import ApiURL from './ApiURL';
import Loader from './Loader';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

class Map extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pins: [],
            isFetching: true,
            loaded: false,
            center: {lat: 52, lng: 0}
        }
        this.fetchPins();
        this.closeModal();
    }
    render() {
        return(
            <div className="map">
                <div className="caseStudyMapBody">
                  <div className="slideout-bar" id="slideout-bar">
                    <button id="close-modal" onClick={this.closeModal}>✖</button>
                    <div class="modal-content-container" id="modal-content-container"></div>
                  </div>
                    {
                        this.state.loaded ?
                        <MyMapComponent
                            isMarkerShown 
                            googleMapURL="https://maps.googleapis.com/maps/api/js?v=3&libraries=geometry,drawing,places"
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div style={{ height: `100%` }} />}
                            mapElement={<div style={{ height: `calc(100% - 30px)` }} />}    
                            lat={ this.state.center.lat }
                            lng={ this.state.center.lng }
                            pins={this.state.pins}

                        /> : ''
                    }
                </div>
                <Loader loading={ this.state.isFetching } loaded={ this.state.loaded } />
            </div>
        );
    }
    fetchPins() {
        this.setState({isFetching: true});
        fetch(ApiURL + '/pins')
            .then(response => response.json())
            .then(result => {
                console.log(result);
                this.setState({
                    pins: result,
                    isFetching: false,
                }, () => {
                    this.generateCenterPoint();
                });
            })
            .catch(e => {
                this.setState({isFetching: false});
            });
    }
    generateCenterPoint() {
        let pins = this.state.pins;
        let latTotal = 0; 
        let lngTotal = 0;
    
        for(let i = 0; i < pins.length; i++) {
            latTotal += parseFloat(pins[i].Latitude);
            lngTotal += parseFloat(pins[i].Longitude);
        }
    
        let lat = latTotal / pins.length;
        let lng = lngTotal / pins.length;
    
        let center = {lat: lat, lng: lng};

        console.log(center);

        this.setState({
            center: center,
            loaded: true
        });
    }
    closeModal() {
      setTimeout(() => {
        let modal = document.getElementById('slideout-bar');
        let innerModal = document.getElementById('modal-content-container');
        innerModal.innerHTML = '';
        modal.classList.remove('open');
        modal.style.transform = 'translateX(0px) translateY(-50%)';
      }, 500);
    }    
}


var MyMapComponent = withGoogleMap((props) =>

  <GoogleMap
    defaultZoom={10}
    defaultCenter={{lat: props.lat, lng: props.lng}}
    options={{
        fullscreenControl: false,
        disableDefaultUI: true,
        styles: exampleMapStyles
    }}
  >
      {
          props.pins.map((item, index) => {
            return(
                <Marker
                    key={index} 
                    position={{ 
                        lat: item.Latitude, 
                        lng: item.Longitude
                    }}
                    onClick={() => {
                      let modal = document.getElementById('slideout-bar');
                      let innerModal = document.getElementById('modal-content-container');
                      let modalWidth = modal.offsetWidth;
                      if(index != item.id) {
                        modal.classList.remove('open');
                        modal.style.transform = 'translateX(0px) translateY(-50%)';
                      }
                      setTimeout(() => {    
                        if(modal.classList.contains('open')) {
                          modal.classList.remove('open');
                          modal.style.transform = 'translateX(0px) translateY(-50%)';
                        } else {
                          modal.classList.add('open');
                          modal.style.transform = 'translateX(' + modalWidth + 'px) translateY(-50%)';
                        }
                        innerModal.innerHTML = '<h2>' + item.Title + '</h2>' + '<img src=' + ApiURL + item.Image.url + '>' + '<p>' + (item.Description ? item.Description : '') + '</p>';
                      }, 500);
                    }}
                />
            )
          })
      }
  </GoogleMap>
)

export default Map;

const exampleMapStyles = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#212121"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#212121"
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "administrative.country",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative.locality",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#181818"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#1b1b1b"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#2c2c2c"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#8a8a8a"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#373737"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#3c3c3c"
        }
      ]
    },
    {
      "featureType": "road.highway.controlled_access",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#4e4e4e"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#000000"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#3d3d3d"
        }
      ]
    }
  ];