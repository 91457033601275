import './App.css';
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import About from './About';
import Home from './Home';
import Nav from './Nav';
import Team from './Team';
import Contact from './Contact';
import Services from './Services';
import CaseStudy from './CaseStudy';
import NotFound from './NotFound';
import Portfolio from './Portfolio';
import Map from './Map';

function App() {
  return (
    <Router>
      <div 
        className={`App`} style={{
        backgroundImage: 'url("")'
      }}>
        <Nav />
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/about" component={About} exact />
          <Route path="/about/team" component={Team} />
          <Route path="/contact" component={Contact} />
          <Route path="/services" component={Services} exact />
          <Route path="/services/:id" component={Services} />
          <Route path="/case-studies" component={CaseStudy} exact />
          <Route path="/case-studies/:id" component={CaseStudy} />
          <Route path="/portfolio" component={Portfolio} />
          <Route path="/map" component={Map} />
          <Route component={NotFound} />
        </Switch>
      </div>
    </Router>
  );
}
export default App;
