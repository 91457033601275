import React from 'react';
import ApiURL from './ApiURL';
import { isMobile } from 'react-device-detect';

class Gallery extends React.Component {
    constructor(props) {
        super(props);

        let columns = props.columns || 5;
        if(isMobile && props.mobileColumns) {
            columns = props.mobileColumns
        }

        this.state = {
            data: props.data.length ? props.data : [],
            currentSlide: 0,
            columns: columns,
            left: '0%'
        }
        
    }
    componentDidMount() {
        this.interval = setTimeout(() => this.progressSlider(), 4000);
    }
    render() {
        return(
            <div className="galleryInner">
                <div 
                    className="galleryStage" 
                    style={{ 
                        width: 200 * this.state.data.length / this.state.columns + '%',
                        left: this.state.left,
                        transitionDuration: this.state.currentSlide === this.state.data.length - 1 ? 0 : '0.25s'
                    }}
                >
                    {
                        this.state.data.map((item, index) => {
                            return(
                                <GalleryItem data={item} key={index} columns={this.state.columns} />
                            )
                        })
                    }
                    {
                        this.state.data.map((item, index) => {
                            return(
                                <GalleryItem data={item} key={index} columns={this.state.columns} />
                            )
                        })
                    }
                </div>
                <div className="aboutSliderControls">
                    <button className="sliderButton" onClick={() => this.regressSlider()}>
                        <i className="fa fa-chevron-left"></i>
                    </button>
                    <button className="sliderButton" onClick={() => this.progressSlider()}>
                        <i className="fa fa-chevron-right"></i>
                    </button>
                    <div className="caseStudyProgressBar">
                        <div 
                            className="caseStudyProgressBarFiller" 
                            id="caseStudyProgressBarFiller"
                            style={{
                                width: 100 * (this.state.currentSlide + 1) / this.state.data.length + '%'
                            }}    
                        ></div>
                    </div>

                </div>
            </div>
        )
    }
    progressSlider() {
        let currentSlide = this.state.currentSlide + 1;
        currentSlide = currentSlide >= this.state.data.length ? 0 : currentSlide;
        let perSlide = 100 / this.state.columns;

        let left = -perSlide * currentSlide + '%';

        this.setState({
            currentSlide: currentSlide,
            left: left
        })
        clearTimeout(this.interval);
        this.interval = setTimeout(() => this.progressSlider(), 4000);
    }

    regressSlider() {
        let currentSlide = this.state.currentSlide - 1;
        currentSlide = currentSlide < 0 ? this.state.data.length - 1 : currentSlide;

        let left = -20 * currentSlide + '%';

        this.setState({
            currentSlide: currentSlide,
            left: left
        })
        clearTimeout(this.interval);
        this.interval = setTimeout(() => this.progressSlider(), 4000);
    }

    componentWillUnmount() {
        clearTimeout(this.interval);
    }
}

class GalleryItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: props.columns,
            data: props.data,
            lightboxOpen: false
        };
    }
    render() {
        return (
            <div 
                className="galleryItem" 
                style={{ 
                    width: 100 / this.state.columns + '%',
                    backgroundImage: 'url(' + ApiURL + (this.state.data.formats.medium ? this.state.data.formats.medium.url : this.state.data.url) + ')'
                }}
                onClick={ () => {
                    this.state.lightboxOpen || this.openLightbox();
                } }
            >
                <div
                    className={`galleryItemLightbox ${ this.state.lightboxOpen ? 'active' : '' }`}
                    style={{ 
                        backgroundImage: 'url(' + ApiURL + (this.state.data ? this.state.data.url : '') + ')'
                    }}
                >
                    <button
                        className="closeLightboxButton"
                        onClick={ () => { this.closeLightbox() }}
                    ><span>✕</span></button>
                </div>
            </div>
        )
    }
    openLightbox() {
        this.setState({
            lightboxOpen: true
        });
    }
    closeLightbox() {
        this.setState({
            lightboxOpen: false
        });
    }
}

export default Gallery;