import React from "react";
import ApiURL from "./ApiURL";
import { Link } from 'react-router-dom';

class CaseStudySlide extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: this.props.active
        }
    }
    render() {
        return(
            <div className={`caseStudySlide  ${ this.props.active ? 'active' : '' } ${ this.props.left ? 'left' : '' } ${ this.props.right ? 'right' : '' }`}>
                <div className="caseStudySlideImage" style={{ backgroundImage: 'url("' + ApiURL + this.props.data.Hero.url + '")'}}></div>
                <div className="caseStudyMeta">
                    <div className="caseStudySubtitle">{ this.props.data.Subtitle }</div>
                    <h2 className="caseStudyTitle">{ this.props.data.Title }</h2>
                    <p className="caseStudyDescription">{ this.props.data.Description }</p>
                    <div className="caseStudyActions">
                        {
                            this.props.data.Longitude && this.props.data.Latitude ? <Link to={`/case-studies/${ this.props.index }/#map`} className="button viewMap">View on a Map</Link> : ''
                        }
                        <Link to={`/case-studies/${ this.props.index }`} className="button primary viewCaseStudy">View Case Study</Link>
                    </div>
                </div>
            </div>
        );
    }
}
export default CaseStudySlide;