import React from 'react';
import ApiURL from './ApiURL';
import Loader from './Loader';
import forwardNav from './forward-arrow.png';
import backwardNav from './backward-arrow.png';

class Portfolio extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            images: [],
            isFetching: true,
            loaded: false,
            scroll: 0,
            stageWidth: 0,
            counter: 0
        }
        this.fetchPortfolioContent();
    }

    componentDidMount() {
        window.onwheel = this.handleScroll.bind(this);
        window.onresize = this.handleResize.bind(this);
    }

    componentWillUnmount() {
        window.onwheel = null;
        window.onresize = null;
    }

    render() {
        return(
            <div className="portfolio">
                <div className="portfolioTitle">
                    <h1>Portfolio</h1>
                </div>
                <div 
                    className="portfolioContainer"
                    id="portfolioContainer"
                >   
                    <div class="scroll-controller">
                        <img src={backwardNav} onClick={this.scrollBackward} alt="back-arrow"></img>
                        <p>Click to scroll</p>
                        <img src={forwardNav} onClick={this.scrollForward} alt="forward-arrow"></img>
                    </div>
                    <div 
                        className="portfolioStage"
                        id="portfolioStage"
                        style={{
                            left: this.state.scroll
                        }}
                    >
                        {
                            this.state.images.map((item, index) => {
                                return(
                                    <PortfolioItem key={index} data={item} />
                                )
                            })
                        }
                    </div>
                </div>
                <Loader loading={ this.state.isFetching } loaded={ this.state.loaded } />
            </div>
        );
    }
    fetchPortfolioContent() {
        this.setState({isFetching: true});
        fetch(ApiURL + '/portfolio')
            .then(response => response.json())
            .then(result => {
                let images = result.Images;

                images = images
                    .map((value) => ({ value, sort: Math.random() }))
                    .sort((a, b) => a.sort - b.sort)
                    .map(({ value }) => value)
                    .slice(0, 30);

                this.setState({
                    images: images,
                    isFetching: false,
                    loaded: true
                });
            })
            .catch(e => {
                this.setState({isFetching: false});
            });
    }

    handleScroll(event) {
        let stage = document.getElementById('portfolioStage');
        let stageWidth = stage.clientWidth;
        let container = document.getElementById('portfolioContainer');
        let containerWidth = container.clientWidth;

        let newScroll = this.state.scroll - event.deltaY;
        console.log(newScroll);

        if(newScroll > 0) {
            newScroll = 0;
        }

        if(newScroll < -stageWidth + containerWidth) {
            newScroll = -stageWidth + containerWidth;
        }

        this.setState({
            scroll: newScroll
        });
    }

    scrollForward() {
        let stage          = document.getElementById('portfolioStage');
        let stageWidth     = stage.clientWidth;
        let container      = document.getElementById('portfolioContainer');
        let containerWidth = container.clientWidth;
        let position = parseInt(stage.style.left, 10);
        let fullWidth = (stageWidth - containerWidth);

        console.log(fullWidth);

        if(-(fullWidth - 200) < position) {
            console.log('hit');
            console.log(position);
            stage.style.left = (position - 400) + 'px';
        }
    }
    scrollBackward() {
        let stage          = document.getElementById('portfolioStage');
        let stageWidth     = stage.clientWidth;
        let container      = document.getElementById('portfolioContainer');
        let containerWidth = container.clientWidth;
        let position = parseInt(stage.style.left, 10);
        let fullWidth = (stageWidth - containerWidth);

        if(position < -200) {
            stage.style.left = (position + 400) + 'px';
        }
    }

    handleResize(event) {

    }
}

class PortfolioItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            aspectRatio: props.data.width / props.data.height,
            lightboxOpen: false
        }

        this.closeLightbox = this.closeLightbox.bind(this);
    }
    render() {

        let gridArea = 'span 1 / span 1';

        if(this.state.aspectRatio < 1) {
            gridArea = 'span 2 / span 1';
        }
        if(this.state.aspectRatio > 1) {
            gridArea = 'span 1 / span 2';
        }

        return(
            <div 
                className="portfolioItemWrap"
                style={{
                    gridArea: gridArea 
                }}
            >
                <div 
                    className="portfolioItem"
                    style={{
                        backgroundImage: "url('" + ApiURL + this.state.data.url +"')",
                    }}
                    onClick={ () => {
                        this.state.lightboxOpen || this.openLightbox();
                    } }
                >
                <div
                    className={`galleryItemLightbox ${ this.state.lightboxOpen ? 'active' : '' }`}
                    style={{ 
                        backgroundImage: 'url(' + ApiURL + (this.state.data ? this.state.data.url : '') + ')'
                    }}
                >
                    <button
                        className="closeLightboxButton"
                        onClick={this.closeLightbox}
                    ><span>✕</span></button>
                </div>
            </div>
            </div>
        );
    }
    openLightbox() {
        this.setState({
            lightboxOpen: true
        });
    }
    closeLightbox() {
        this.setState({
            lightboxOpen: false
        });
    }
}

export default Portfolio;